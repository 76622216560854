<template>
  <div>
    <b-row>
      <b-col
        v-if="orderedDisks.length > 0"
        cols="6"
      >
        <b-card
          :title="`Disks (${orderedDisks.length})`"
        >
          <div class="table-responsive config-table-wrapper">
            <table class="table table-sm table-borderless">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Used</th>
                  <th>Available</th>
                  <th>Size</th>
                  <th>Usage</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="disk in orderedDisks"
                  :key="disk.id"
                >
                  <td>{{ disk.name }}</td>
                  <td>{{ (disk.totalSize - disk.availableSize) | prettyBytes }}</td>
                  <td>{{ disk.availableSize | prettyBytes }}</td>
                  <td>{{ disk.totalSize | prettyBytes }}</td>
                  <td>{{ Math.round((disk.totalSize - disk.availableSize) * 100 / disk.totalSize) }}%</td>
                </tr>

              </tbody>
            </table>
          </div>
        </b-card>
      </b-col>

      <b-col
        v-if="orderedLicenses.length > 0"
        cols="6"
      >
        <b-card
          :title="`Licenses (${orderedLicenses.length})`"
        >
          <div class="table-responsive config-table-wrapper">
            <table class="table table-sm table-borderless">
              <thead>
                <tr>
                  <th>Swid</th>
                  <th>Type</th>
                  <th>Status</th>
                  <th>Activated on</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="license in orderedLicenses"
                  :key="license.id"
                >
                  <td>{{ license.swid }}</td>
                  <td>{{ license.type }}</td>
                  <td>{{ license.status }}</td>
                  <td>{{ license.activationDate | formatDateTimeTz(asup.timezone) }}</td>
                </tr>

              </tbody>
            </table>
          </div>
        </b-card>
      </b-col>

      <b-col
        v-if="orderedNodes.length > 0"
        cols="12"
      >
        <b-card
          :title="`Nodes (${orderedNodes.length})`"
        >
          <div class="table-responsive config-table-wrapper">
            <table class="table table-sm table-borderless">
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Version</th>
                  <th>Updated</th>
                  <th>Uptime</th>
                  <th>Status description</th>
                  <th>PowerProtect Version</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="node in orderedNodes"
                  :key="node.id"
                >
                  <td>{{ node.status }}</td>
                  <td>{{ node.version }}</td>
                  <td>{{ node.lastUpdated | formatDateTimeTz(asup.timezone) }}</td>
                  <td>{{ node.uptimeInDays }} days</td>
                  <td>{{ node.statusDescription }}</td>
                  <td>{{ node.powerProtectVersion }}</td>
                </tr>

              </tbody>
            </table>
          </div>
        </b-card>
      </b-col>

      <b-col
        v-if="orderedProtectionPolicies.length > 0"
        cols="12"
      >
        <b-card
          :title="`Protection Policies (${orderedProtectionPolicies.length})`"
        >
          <div class="table-responsive config-table-wrapper">
            <table class="table table-sm table-borderless">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Enabled</th>
                  <th>Passive</th>
                  <th>Priority</th>
                  <th>Asset type</th>
                  <th>Created at</th>
                  <th>Encrypted</th>
                  <th>Force Full</th>
                  <th>Updated at</th>
                  <th>Description</th>
                  <th>Data consistency</th>
                  <th>Target Storage Provision Strategy</th>
                  <th>Number of assets</th>
                  <th>Total asset capacity</th>
                  <th>Total asset protection capacity</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="policy in orderedProtectionPolicies"
                  :key="policy.id"
                >
                  <td>{{ policy.name }}</td>
                  <td>{{ policy.type }}</td>
                  <td>{{ policy.enabled }}</td>
                  <td>{{ policy.passive }}</td>
                  <td>{{ policy.priority }}</td>
                  <td>{{ policy.assetType }}</td>
                  <td>{{ policy.createdAt | formatDateTimeTz(asup.timezone) }}</td>
                  <td>{{ policy.encrypted }}</td>
                  <td>{{ policy.forceFull }}</td>
                  <td>{{ policy.updatedAt | formatDateTimeTz(asup.timezone) }}</td>
                  <td>{{ policy.description }}</td>
                  <td>{{ policy.dataConsistency }}</td>
                  <td>{{ policy.targetStorageProvisionStrategy }}</td>
                  <td>{{ policy.summary === null ? null : policy.summary.numberOfAssets }}</td>
                  <td>{{ policy.summary === null ? null : policy.summary.totalAssetCapacity| prettyBytes }}</td>
                  <td>{{ policy.summary === null ? null : policy.summary.totalAssetProtectionCapacity| prettyBytes }}</td>
                </tr>

              </tbody>
            </table>
          </div>
        </b-card>
      </b-col>

      <b-col
        v-if="orderedStorage.length > 0"
        cols="12"
      >
        <b-card
          :title="`Storage (${orderedStorage.length})`"
        >
          <div class="table-responsive config-table-wrapper">
            <table class="table table-sm table-borderless">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Purpose</th>
                  <th>Readiness</th>
                  <th>Description</th>
                  <th>Capacity Utilization</th>
                  <th>Replication Encryption mode</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="storage in orderedStorage"
                  :key="storage.id"
                >
                  <td>{{ storage.name }}</td>
                  <td>{{ storage.type }}</td>
                  <td>{{ storage.purpose }}</td>
                  <td>{{ storage.readiness }}</td>
                  <td>{{ storage.description }}</td>
                  <td>{{ Math.round(storage.capacityUtilization) }}%</td>
                  <td>{{ storage.replicationEncryptionMode }}</td>
                </tr>

              </tbody>
            </table>
          </div>
        </b-card>
      </b-col>

      <b-col
        v-if="orderedInventorySource.length > 0"
        cols="12"
      >
        <b-card
          :title="`Inventory Source (${orderedInventorySource.length})`"
        >
          <div class="table-responsive config-table-wrapper">
            <table class="table table-sm table-borderless">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Version</th>
                  <th>Vendor</th>
                  <th>Purpose</th>
                  <th>Address</th>
                  <th>Port</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="inventorySource in orderedInventorySource"
                  :key="inventorySource.id"
                >
                  <td>{{ inventorySource.name }}</td>
                  <td>{{ inventorySource.type }}</td>
                  <td>{{ inventorySource.version }}</td>
                  <td>{{ inventorySource.vendor }}</td>
                  <td>{{ inventorySource.purpose }}</td>
                  <td>{{ inventorySource.address }}</td>
                  <td>{{ inventorySource.port }}</td>
                </tr>

              </tbody>
            </table>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import { BRow, BCol, BCard } from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
  },
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    asset: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    orderedDisks() {
      return this.asup.details.disks.concat().sort((a, b) => (a.name > b.name ? 1 : -1))
    },
    orderedNodes() {
      return this.asup.details.nodes.concat().sort((a, b) => (a.systemId > b.systemId ? 1 : -1))
    },
    orderedLicenses() {
      return this.asup.details.licenses.concat().sort((a, b) => (a.key > b.key ? 1 : -1))
    },
    orderedProtectionPolicies() {
      return this.asup.details.protectionPolicies.concat().sort((a, b) => (a.name > b.name ? 1 : -1))
    },
    orderedStorage() {
      return this.asup.details.storage.concat()
        .filter(x => x.type === 'DATA_DOMAIN_SYSTEM' || x.type === 'DATA_DOMAIN_APPLIANCE_POOL') // same filter as in PPDM GUI
        .sort((a, b) => (a.name > b.name ? 1 : -1))
    },
    orderedInventorySource() {
      return this.asup.details.inventorySources.concat().sort((a, b) => (a.name > b.name ? 1 : -1))
    },
  },
}
</script>

<style scoped>
  .config-table-wrapper {
    max-height: 400px;
    overflow-y: auto
  }
</style>
